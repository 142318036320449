import React from 'react';

import Layout from '../components/Layout';

const Registry = (props) => (
  <Layout fullMenu location={props.location} title="Registry">
    <article id="main">
      <header>
        <h2>Registry</h2>
        {/* <p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p> */}
      </header>
      <section className="wrapper style5">
        <div className="inner">
          {/* <h3>Lorem ipsum dolor</h3> */}
          <p>
            We will not be having a registry. We appreciate that you are
            celebrating our wedding with us and your presence is a blessing for
            us and for our families.
          </p>
          <p>
            If you would like to support us, please consider contributing to our
            honeymoon fund. Thanks!
          </p>

          <hr />
          <br />
          <p>
            If you would like to send a gift electronically, please use one of
            the methods below.
          </p>
          <ul>
            <li>
              <a
                href="https://paypal.me/leetosc?country.x=US&locale.x=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Paypal
              </a>
            </li>
            <li>Venmo: @leeto</li>
            <li>Zelle: dennynguyen0@gmail.com</li>
          </ul>

          <br />
          <p>Thank you so much for supporting us! </p>

          {/* <h4>paypal link</h4>
          <p>
            Nam sapien ante, varius in pulvinar vitae, rhoncus id massa. Donec
            varius ex in mauris ornare, eget euismod urna egestas. Etiam lacinia
            tempor ipsum, sodales porttitor justo. Aliquam dolor quam, semper in
            tortor eu, volutpat efficitur quam. Fusce nec fermentum nisl. Aenean
            erat diam, tempus aliquet erat.
          </p> */}
        </div>
      </section>
    </article>
  </Layout>
);

export default Registry;
